import { makeBidFactory } from "./bids";
import { ICustomizations } from "./providers/types";
import { isMobile } from "./utils/mobile";

export const origin =
  window != window.top && window.location.ancestorOrigins
    ? window.location.ancestorOrigins[
        window.location.ancestorOrigins.length - 1
      ]
    : String(document.location);
export const referrer = String(document.location);

export const userLocation = {
  city: {name: ''},
  state: {code: ''},
  country: {code: ''},
  continent: {code: ''}
}

export function appendViewerHeaders (res: Response) {
  const city = res.headers.get('cloudfront-viewer-city')
  if (city) userLocation.city.name = city.toLowerCase()
  const region = res.headers.get('cloudfront-viewer-country-region')
  if (region) userLocation.state.code = region.toUpperCase()
  const country = res.headers.get('cloudfront-viewer-country')
  if (country) userLocation.country.code = country.toUpperCase()
  return res.json()
}

export function createBidstreamMediaSrc(
  zid: number|string,
  friendly: string,
  random: number
) {
  let url = `https://ads.bidstreamserver.com/servlet/view/banner/javascript/zone?zid=${zid}&friendly=${friendly}&pid=1&refresh=60&refresh_limit=1&rmpid=true&random=${random}&encode=1`;
  url += `&origin=${encodeURIComponent(origin)}`;
  if (referrer) url += `&referrer=${referrer}`;
  return url;
}

export function appendScript(root: Element) {
  return function (src: string) {
    const script = document.createElement("script");
    script.src = src;
    root.appendChild(script);
  };
}

export function getRandom() {
  return Math.floor(Math.random() * 1e8);
}

interface AuctionBid {
  adUnitCode: string;
  bidder: string;
  cpm: number;
}

interface AuctionEndEvent { 
  bidsReceived: AuctionBid[]
}

const googleTagIndexBySize: { [size: string]: number } = {}
export function generateAds(theme: ICustomizations, { 
  prefix,
  onBidderDone,
  onAuctionEnd,
  onAdRenderSucceeded,
}: { 
  prefix?: string
  onBidderDone?: (event: unknown) => void
  onAuctionEnd?: (winningBids: AuctionBid[]) => void
  onAdRenderSucceeded?: (event: { bid: AuctionBid }) => void
} = {}) {
  let appendScripts: [string, string | HTMLScriptElement][] = [];
  let headScripts: HTMLScriptElement[] = [];

  const mobile = isMobile();
  const withBidstream = (zid: number|string, width = 300, height = 250) => {
    if (!zid) return "";
    if (theme.adType === "BidstreamLite") {
      const [tagId, dataZid] = decodeURIComponent(String(zid)).split(':')
      const ad = `<div data-ad-tagid="${tagId}" data-zid="${dataZid}" data-name="SW - ${theme.partnerCode} ${width}x${height}"></div>`
      return ad
    }
    if (theme.adType === "BidstreamNew") {
      const id = `ad-${getRandom()}`;
      const ad = `<div id="${id}"></div>`
      const script = document.createElement("script");
      script.type = 'text/javascript'
      script.innerHTML = `var _avp = _avp || [];
      _avp.push({ tagid: '${id}', alias: '/', type: 'banner', zid: ${zid}, pid: 0, iframe: true, width: ${width}, height: ${height}, refresh: 60, refresh_limit: 1, rmpid: true, secure: true });`

      appendScripts.push([id, script]);
      return ad
    }
    const random = getRandom();
    const id = `friendly_${random}`;
    const ad = `<ins id="${id}"></ins>`;
    const script = createBidstreamMediaSrc(zid, id, random);
    appendScripts.push([id, script]);
    return ad;
  };
  const displayZids = mobile ? theme.mobileZids : theme.displayZids;
  let zids = [...displayZids]
  let adUnits = []
  try {
    adUnits = eval(theme.adUnits) || []
  } catch (e) {
    console.log('Error evaling adunits')
  }
  
  const googleUnitsBySize = adUnits.reduce((acc: any, unit: any) => {
    const isMobileUnit = unit.code.toLowerCase().indexOf('mobile') !== -1
    if (mobile && !isMobileUnit) return acc // dont use desktop on mobile
    if (!mobile && isMobileUnit) return acc // dont use mobile on desktop
    const size = unit.mediaTypes.banner.sizes.map((v: number[]) => v.join('x')).join(',')
    if (!acc[size]) acc[size] = []
    acc[size].push(unit)
    return acc
  }, {})
  const makeBids = makeBidFactory(theme)
  const ads = {
    useBidstream: withBidstream,
    useBidstreamDisplay: () => {
      const zid = zids.shift()
      if (!zids.length) zids = [...displayZids]
      return withBidstream(zid || 0)
    },
    useZipRecruiter: (id: number, short = false) => {
      const zipRecruiterUri = 'https://bit.ly/33Rqhv3'
      const zipRecruiterImage = `https://cache.sellwild.com/ziprecruiter/${id}_v2.jpg`
      return `<a href="${zipRecruiterUri}" style="background-image: url(${zipRecruiterImage})" target="_blank" class="sellwild-zip-ad ${short ? "sellwild-listing-short" : ""}" rel="noopener noreferrer">
        <div class="listing-overlay"></div>
      </a>`
    },
    useBids: (size: [number, number]) => makeBids({ mobile, size }),
    useBidstreamSkyscraper: () => withBidstream(theme.skyscraperZid),
    useTopBanner: () => mobile ? withBidstream(theme.mobileBannerZid, 320, 50) : withBidstream(theme.bannerZid, 728, 90),
    useBottomBanner: () => mobile ? withBidstream(theme.mobileBannerZid, 320, 50) : withBidstream(theme.bottomBannerZid, 728, 90),
    useMobileBanner: () => withBidstream(theme.mobileBannerZid, 320, 50),
    initialize: () => {
      switch (theme.adType) {
        case "MediaFuse": return ads.initializeMediaFuse()
        case "BidstreamNew": return ads.initializeBidstreamNew()
        case "BidstreamLite": return ads.initializeBidstreamLite()
        case "GTag": return ads.initializeGoogleTag()
        case "Prebid":
          return ads.initializePrebid()
      }
    },
    initializeBidstreamNew: () => {
      var id = 'bidstream-new-script'
      if (document.getElementById(id)) return

      var script = document.createElement("script");
      script.id = id
      script.innerHTML = `var _avp = _avp || [];
      (function() {
        var s = document.createElement('script');
        s.type = 'text/javascript'; s.async = true; s.src = 'https://ads.bidstreamserver.com/js/libcode3.js';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })();`
      headScripts.push(script);
    },
    initializeBidstreamLite: () => {
      var id = 'bidstream-lite-script'
      if (document.getElementById(id)) return

      var script = document.createElement("script");
      script.id = id
      script.innerHTML = `var _hbopts = { alias: '/', type: 'banner', pid: 0 };
      var _hbwrap = _hbwrap || [];
      (function() {
        var hbldr = function (url, resolution, cachebuster, millis, referrer) {
          var s = document.createElement('script'); s.type = 'text/javascript';
          s.async = true; s.src = 'https://' + url + '&resolution=' + resolution +
            '&random=' + cachebuster + '&millis=' + millis + '&referrer=' + referrer;
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        hbldr(
          'ads.bidstreamserver.com/servlet/hbwrap?stack=${theme.bidstreamStack}&pid=0',
          (window.innerWidth||screen.width)+'x'+(window.innerHeight||screen.height),
          Math.floor(89999999*Math.random()+10000000), new Date().getTime(), encodeURIComponent(
            (window!=top&&window.location.ancestorOrigins)?
              window.location.ancestorOrigins[window.location.ancestorOrigins.length-1]:document.location
          )
        );
      })();`
      headScripts.push(script);
    },
    initializeMediaFuse: () => {
      var id = 'media-fuse-script'
      if (document.getElementById(id)) return

      var gpt = document.createElement("script");
      gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      gpt.async = true;
      headScripts.push(gpt);

      var mediaFuse = document.createElement("script");
      mediaFuse.id = id
      mediaFuse.innerHTML = `!(function () { window.googletag = window.googletag || {}; window.vmpbjs = window.vmpbjs || {}; window.vpb = window.vpb || {}; vpb.fastLoad = true; googletag.cmd = googletag.cmd || []; vmpbjs.cmd = vmpbjs.cmd || []; var cmds = []; try{ cmds = googletag.cmd.slice(0); googletag.cmd.length = 0; }catch(e){} var ready = false; function exec(cb) { return cb.call(googletag); } var overriden = false; googletag.cmd.push(function () { overriden = true; googletag.cmd.unshift = function (cb) { if (ready) { return exec(cb); } cmds.unshift(cb); if (cb._startgpt) { ready = true; for (var k = 0; k < cmds.length; k++) { exec(cmds[k]); } } }; googletag.cmd.push = function (cb) { if (ready) { return exec(cb); } cmds.push(cb); }; }); if(!overriden){ googletag.cmd.push = function (cb) { cmds.push(cb); }; googletag.cmd.unshift = function (cb) { cmds.unshift(cb); if (cb._startgpt) { ready = true; if (googletag.apiReady) { cmds.forEach(function (cb) { googletag.cmd.push(cb); }); } else { googletag.cmd = cmds; } } }; } var dayMs = 36e5, cb = parseInt(Date.now() / dayMs), vpbSrc = '//player.ads.sellwild.com/prebidlink/' + cb + '/${theme.mediaFuseWrapper}', pbSrc = vpbSrc.replace('wrapper_hb', 'hb'), gptSrc = '//securepubads.g.doubleclick.net/tag/js/gpt.js', c = document.head || document.body || document.documentElement; function loadScript(src, cb) { var s = document.createElement('script'); s.src = src; s.defer=false; c.appendChild(s); s.onload = cb; s.onerror = function(){ var fn = function(){}; fn._startgpt = true; googletag.cmd.unshift(fn); }; return s; } loadScript(pbSrc); loadScript(gptSrc); loadScript(vpbSrc); })()`
      headScripts.push(mediaFuse);
    },
    initializeGoogleTag: () => {
      var gpt = document.createElement("script");
      gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      gpt.async = true;

      headScripts.push(gpt);
    },
    initializePrebid: () => {
      const id = "sellwild-prebid-init"
      const initialized = document.getElementById(id)
      if (!initialized) {
        var gpt = document.createElement("script");
        gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
        gpt.async = true;

        var prebid = document.createElement("script");
        prebid.src = "https://cache.sellwild.com/prebid/video_8_49_0.js";
        // prebid.src = "https://cache.sellwild.com/prebid/display_7_42_0.js";
        // prebid.src = "https://cache.sellwild.com/prebid7_42_0.js";
        prebid.id = id;
        prebid.async = true;

        var googletag = document.createElement("script");

        var ortb2 = ortb2Generator(theme)
        var schain = schainGenerator(theme)

        googletag.innerHTML = `
        var PREBID_TIMEOUT = 1000;
        var FAILSAFE_TIMEOUT = 3000 + ${(theme.prebidDefer * 1000 || 0) + 16};
        
        // ======== DO NOT EDIT BELOW THIS LINE =========== //
        var googletag = googletag || {};
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(function() {
          if (!${theme.prebidDefer}) googletag.pubads().disableInitialLoad();
        });
        
        var swpbjs = swpbjs || {};
        swpbjs.que = swpbjs.que || [];
        swpbjs.refreshCount = 0;
        swpbjs.displayAdUnitCodes = [];
        swpbjs.bidderSettings = swpbjs.bidderSettings || {};
        swpbjs.bidderSettings.ix = {storageAllowed: true};
        swpbjs.bidderSettings.amx = {storageAllowed: true};
        swpbjs.bidderSettings.appnexus = {storageAllowed: true};
        swpbjs.bidderSettings.onetag = {storageAllowed: true};

        swpbjs.que.push(function() {
          swpbjs.setConfig({
            userSync: {
              filterSettings: {
                iframe: {
                  bidders: '*',      // '*' represents all bidders
                  filter: 'include'
                }
              },
              userIds: [
                {
                  name: "sharedId", //"pubCommonId" as a name is supported for backwards compatibility,
                  storage: {
                    name: "_sharedID", // name of the 1st party cookie, _pubcid is supported for backwards compatibility
                    type: "cookie",
                    expires: 30,
                  },
                },
              ],
            },
            enableTIDs: true,
            priceGranularity: "medium",
            enableSendAllBids: true,
            bidderTimeout: 3000,
            ortb2: ${JSON.stringify(ortb2)},
            schain: ${JSON.stringify(schain)},
          })
        });

        function requestSellwildBids () {
          clearTimeout(swpbjs.requestTimerId)
          swpbjs.requestTimerId = setTimeout(function () {
            swpbjs.initAdserverSet = false;
            swpbjs.requestBids({
              adUnitCodes: swpbjs.displayAdUnitCodes,
              bidsBackHandler: initSellwildAdserver,
              timeout: PREBID_TIMEOUT
            });
          }, ${(theme.prebidDefer * 1000 || 0) + 16})
        }
        
        function initSellwildAdserver(bids) {
            if (swpbjs.initAdserverSet) return;
            swpbjs.initAdserverSet = true;
            googletag.cmd.push(function() {
                swpbjs.setTargetingForGPTAsync && swpbjs.setTargetingForGPTAsync();
                googletag.pubads().refresh();
            });

            if (swpbjs.refreshCount < ${theme.adRefreshMax}) {
              swpbjs.refreshTimerId = clearTimeout(swpbjs.refreshTimerId)
              swpbjs.refreshTimerId = setTimeout(() => {
                swpbjs.refreshCount++
                requestSellwildBids()
              }, ${theme.adRefreshInterval || 30000})
            } else if (swpbjs.refreshCount >= ${theme.adRefreshMax}) {
            }
        }
        
        // in case PBJS doesn't load
        setTimeout(function() {
          initSellwildAdserver();
        }, FAILSAFE_TIMEOUT);
        `;

        if (onBidderDone || onAuctionEnd) {
          window.swpbjs = window.swpbjs || {};
          window.swpbjs.que = window.swpbjs.que || [];
          window.swpbjs.que.push(() => {
            if (onAuctionEnd) window.swpbjs.onEvent('auctionEnd', (event: AuctionEndEvent) => {
              if (event && event.bidsReceived) {
                const bidsByAdUnitCode = event.bidsReceived.reduce((acc, bid) => {
                  if (!acc[bid.adUnitCode]) acc[bid.adUnitCode] = []
                  acc[bid.adUnitCode].push(bid)
                  return acc
                }, {} as Record<string, AuctionBid[]>)
                
                const winningBids = Object.keys(bidsByAdUnitCode).map((code) => {
                  return bidsByAdUnitCode[code].sort((a, b) => b.cpm - a.cpm)[0]
                })
                onAuctionEnd(winningBids)
              }
            })
            if (onAdRenderSucceeded) window.swpbjs.onEvent('adRenderSucceeded', onAdRenderSucceeded)
            if (onBidderDone) window.swpbjs.onEvent('bidderDone', onBidderDone)
          })
        }

        headScripts.splice(
            0, 
            0,
            gpt, 
            prebid, 
            googletag
        );
      }
    },
    useGoogleTag: (tagDesc: string, tagSize: [number, number], tag: string) => {
      var tagDescStr = JSON.stringify(tagDesc);
      var tagSizeStr = JSON.stringify(tagSize);
      if (!document.getElementById(tag)) {
        var isPrebid = (theme.adType || '').indexOf('Prebid') === 0
        var googletag = document.createElement("script");
        var adscript = document.createElement("script");

        googletag.innerHTML = `
          window.googletag = window.googletag || {cmd: []};
          googletag.cmd.push(function() {
              googletag.defineSlot(${isPrebid ? tagDescStr : tagDesc}, ${tagSizeStr}, '${tag}')
              .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
          });`;
  
        adscript.innerHTML = `
          window.googletag.cmd.push(function() { 
            window.googletag.display('${tag}'); 
          });
          
          ${isPrebid ? `
            swpbjs.que.push(function() {
              swpbjs.addAdUnits(${JSON.stringify([{
                code: tag,
                mediaTypes: {
                  banner: {
                    sizes: [tagSize]
                  }
                },
                bids: makeBids({ mobile, size: tagSize })
              }])});
              swpbjs.displayAdUnitCodes.push("${tag}")
              requestSellwildBids()
            });
          ` : ''}
        `;
  
        headScripts.push(googletag);
        appendScripts.push([tag, adscript]);
      }

      return `<!-- ${tagDescStr} --><div id="${tag}" style='width: ${tagSize[0]}px; height: ${tagSize[1]}px; margin: auto;'></div>`;
    },
    useGoogleDisplay: (size: [number, number]) => {
      const sizeStr = size.join('x')
      if (!googleTagIndexBySize[sizeStr]) googleTagIndexBySize[sizeStr] = 0
      const tag = `${prefix || 'google-tag'}-${sizeStr}-${googleTagIndexBySize[sizeStr]}`;
      var isPrebid = (theme.adType || '').indexOf('Prebid') === 0
      if (theme.adType === 'GTag' || isPrebid) {
        googleTagIndexBySize[sizeStr]++
        const code = `/21824729475/${theme.slug}-${mobile ? 'mobile' : 'desktop'}-${sizeStr}`
        return ads.useGoogleTag(code, size, tag)
      }

      if (!googleUnitsBySize[sizeStr] || !googleUnitsBySize[sizeStr][googleTagIndexBySize[sizeStr]]) return '';
      const item = googleUnitsBySize[sizeStr][googleTagIndexBySize[sizeStr]]
      googleTagIndexBySize[sizeStr]++
      return ads.useGoogleTag(`"${item.code}"`, size, tag)
    },
    useAudigent: () => {
      if (theme.audigent) {
        var audigentTag = document.createElement('script')
        audigentTag.innerHTML = `(function(w,d,t,u){
            var a=d.createElement(t);a.async=1;a.src=u+"?url="+encodeURIComponent(w.location.href)+"&ref="+encodeURIComponent(d.referrer);
            var s=d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(a,s);
        })(window, document, 'script', 'https://a.ad.gt/api/v1/u/matches/359')`
        headScripts.push(audigentTag);
      }
    },
    removeScripts: (root: Element) => {
      appendScripts.forEach(([id]) => {
        const element = document.getElementById(id)
        if (element && element.parentElement) {
          element.parentElement.remove()
        }
      })
    },
    appendScripts: async (root: Element) => {
      try {
        headScripts.forEach((script) => {
          try {
            document.head.appendChild(script);
          } catch (e) {
            console.log(e);
          }
        });

        if (theme.adDisableDisplay) {
          ads.removeScripts(root)
          return
        }
        if (theme.adGeoBlock) {
          const { continents, countries, states, cities } = theme.adGeoBlock
          // const result = await fetch('https://tbd4rmdvjk.execute-api.us-east-1.amazonaws.com/dev/geo/ip').then((res) => res.json()).catch(err => ({}))
          if (continents && userLocation.continent && userLocation.continent.code && continents.toUpperCase().indexOf(userLocation.continent.code.toUpperCase()) !== -1) {
            ads.removeScripts(root)
            return { blocked: true, type: 'continent', value: userLocation.continent.code }
          }
          if (countries && userLocation.country && userLocation.country.code && countries.toUpperCase().indexOf(userLocation.country.code.toUpperCase()) !== -1) {
            ads.removeScripts(root)
            return { blocked: true, type: 'country', value: userLocation.country.code }
          }
          if (states && userLocation.state && userLocation.state.code && states.toUpperCase().indexOf(userLocation.state.code.toUpperCase()) !== -1) {
            ads.removeScripts(root)
            return { blocked: true, type: 'state', value: userLocation.state.code }
          }
          if (cities && userLocation.city && userLocation.city.name && cities.toLowerCase().indexOf(userLocation.city.name.toLowerCase()) !== -1) {
            ads.removeScripts(root)
            return { blocked: true, type: 'city', value: userLocation.city.name }
          }
        }
        appendScripts.forEach(([id, src]) => {
          const element = root.querySelector(`#${id}`);
          if (element && typeof src === "string") {
            appendScript(element)(src);
          } else if (element) {
            try {
              element.appendChild(src as any);
            } catch (e) {
              console.log('Failure to append script', id, e);
            }
          } else {
            console.log('Element not found for append', id)
          }
        });
        headScripts = [];
        appendScripts = [];
      } catch (e) {
        console.log(e);
      }
    },
  };
  return ads;
}

function ortb2Generator (theme: ICustomizations) {
  const site = ortb2SiteGenerator(theme)
  const user = ortb2UserGenerator(theme)
  return {
    site,
    user,
  }
}

function schainGenerator (theme: ICustomizations) {
  if (theme.schainSid) {
    return {
      "validation": "strict",
      "config": {
        "ver":"1.0",
        "complete": 1,
        "nodes": [
          {
            "asi": "sellwild.com",
            "sid": theme.schainSid,
            "hp": 1
          }
        ]
      }
    }
  }
  return undefined
}

function ortb2SiteGenerator (theme: ICustomizations) {
  const site: Partial<Ortb2Site> = {
    name: theme.partnerCode,
    domain: window.location.host,
    page: window.location.href,
  }

  const keywordEl = document.head.querySelector('meta[name="keywords"]')
  if (keywordEl) {
    site.keywords = keywordEl.getAttribute('content') || ''
  }

  if (theme.iabCats) {
    site.cat = theme.iabCats
    site.pagecat = theme.iabCats
    site.sectioncat = theme.iabCats
  }

  return site
}

function ortb2UserGenerator (theme: ICustomizations) {
  const user: Partial<Ortb2User> = {}
  return user
}

interface Ortb2Site {
  name: string
  domain: string
  cat: string[]
  sectioncat: string[]
  pagecat: string[]
  page: string
  ref: string
  keywords: string
  search: string
  content: {
    userrating: string
    data: [
      {
        name: string
        ext: {
          segtax: number
          cids: string[]
        }
        segment: { id: string }[]
      }
    ]
  }
  ext: {
    data: {
      pageType: string
      category: string
    }
  }
}

interface Ortb2User {
  yob: number;
  gender: "m" | "f";
  keywords: string;
  data: [
    {
      name: string;
      ext: { segtax: number };
      segment: { id: string }[];
    }
  ];
  ext: {
    data: {
      registered: true;
      interests: string[];
    };
  };
}
